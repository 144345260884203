* {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

/* TODO pesquisar solução melhor para overscrolling*/
html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #e8e8e8 !important;
}

.main-container {
  min-height: calc(90vh - 120px) ;
  width: 80px;
  display: flex;
}

main {
  margin-top: 100px;
  font-size: 25px;
  padding: 50px 60px;
}

.sidebar {
  top: 160px;
  background: #E3F1FA;
  color: #111111;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  position: fixed;
}

.routes {
  color: white;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 30px;
}

.logo {
  font-size: 20px;
  line-height: 0;
}

.link {
  display: flex;
  color: #666D81;
  gap: 10px;
  padding: 20px 30px;
  border-left: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-left: 3px solid #0D47A1;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  background: aliceblue;
}

.active {
  border-left: 3px solid #0D47A1;
  background: aliceblue;
}

.link-text {
  white-space: nowrap;
  font-size: 15px;
}

.logout {
  color: #666D81;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 30px;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
